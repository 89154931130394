<template>
  <Stack
    align="center"
    justify="between"
    :gap="isVat ? '2xs' : 'sm'"
    class="w-fit"
  >
    <input
      type="checkbox"
      class="ui-hidden"
      :id="id"
      :value="value"
      :name="name"
      :disabled="disabled"
      :checked="value || checked"
    />
    <label
      :for="id"
      :class="[
        'ui-text-base ui-font-semi',
        isVat && 'ui-text-white ui-text-sm',
        labelVisible === false && 'ui-sr-only',
        disabled && 'ui-text-grey-default',
      ]"
    >
      {{ label }}
    </label>
    <Stack
      align="center"
      :data-test-id="`${dataTestId}-${checked ? 'checked' : 'unchecked'}`"
      :class="[
        'ui-h-[20px] ui-cursor-pointer ui-border-md ui-rounded-[100px] ui-p-[2px] relative',
        !isVat && 'hover:ui-ring-[6px] hover:ui-ring-blue-lighter',
        !isVat && 'active:ui-ring-[6px] active:ui-ring-blue-light',
        isVat ? 'ui-w-[54px]' : 'ui-w-[40px]',
        checked &&
          !disabled &&
          !isVat &&
          'ui-border-mkm-blue-default ui-bg-mkm-blue-default',
        checked &&
          !disabled &&
          isVat &&
          ' ui-bg-mkm-yellow-default ui-border-mkm-yellow-default',
        checked && disabled && 'ui-border-grey-default ui-bg-grey-default',
        !checked && !disabled && 'ui-border-charcoal-light ui-bg-white',
        !checked &&
          !disabled &&
          !isVat &&
          'ui-border-charcoal-light ui-bg-white',
        !checked && !disabled && isVat && 'ui-border-white ui-bg-white',
        !checked && disabled && 'ui-border-grey-default ui-bg-white',
        disabled && '!ui-cursor-default hover:!ring-0 active:!ring-0',
      ]"
      @click="toggleCheckbox"
    >
      <span
        v-show="checked && isVat"
        class="!ui-absolute !ui-left-3xs ui-text-sm ui-font-bold ui-text-charcoal-default"
      >
        INC
      </span>

      <span
        v-show="!checked && isVat"
        class="!ui-absolute !ui-right-3xs ui-text-sm ui-font-bold ui-text-charcoal-default"
      >
        EXC
      </span>

      <span
        :class="[
          'ui-w-[12px] ui-h-[12px] ui-rounded-round ui-transition-transform ui-duration-300 ui-ease-in-out',
          isVat && checked && '!ui-bg-charcoal-default ui-translate-x-[34px]',
          !isVat && checked && '!ui-bg-white ui-translate-x-[20px]',
          !checked && !disabled && !isVat && 'ui-bg-charcoal-light',
          !checked && !disabled && isVat && 'ui-bg-charcoal-default',
          !checked && disabled && 'ui-bg-grey-default',
        ]"
      />
    </Stack>
  </Stack>
</template>

<script lang="ts" setup>
import Stack from "../../Layout/Stack/Stack.vue";
import type { SwitchProps } from "./types";
import { ref, toRefs, watch } from "vue";

const props = withDefaults(defineProps<SwitchProps>(), {
  modelValue: false,
  labelVisible: true,
  disabled: false,
});

const { id, modelValue, value, name, label, labelVisible, disabled } =
  toRefs(props);

const emit = defineEmits<{
  (event: "update:modelValue"): void;
}>();

const checked = ref<boolean>(modelValue.value);

const toggleCheckbox = (): void => {
  if (disabled.value) return;

  checked.value = !checked.value;
  emit("update:modelValue");
};

watch(modelValue, () => {
  checked.value = modelValue.value;
});
</script>
